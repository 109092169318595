import { Icon } from '../../shared/ui/icon/icon.tsx'
import { Button, LinkButton } from '../../shared/ui/button/button.tsx'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, range } from '../../shared/lib/utils.ts'
import React, { useEffect, useRef, useState } from 'react'
import { SubscriptionPlan } from '../../shared/api/chatApi.ts'
import {
  // DiscountMonthsImage,
  DiscountYearImage,
  PaymentsImage,
} from '../../shared/ui/images/images.tsx'
import { useBuyStore } from './buyStore.ts'
import { useSnapshot } from 'valtio/react'
import { RegLoginForm } from '../auth/login.tsx'
import { VideoAvatarBlock } from './OnboardingView.tsx'
import { getVideoUrl } from './tutorials.tsx'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppModule } from '../appContext.ts'
import { Carousel } from '../../shared/ui/carousel/carousel.tsx'
import { useIsMobile } from '../../shared/lib/hooks.ts'
import Rive from '@rive-app/react-canvas'
import { ContactsView } from '../contacts/contactsView.tsx'
import { urls } from '../../shared/urls.ts'

function ChoosePlanTitle() {
  return (
    <>
      <div className="mb-108">
        <Rive
          className="relative -top-20 left-[300px] -mb-24 h-[120px] w-[800px]"
          src="/animations/soundwave_background2.riv"
        />
      </div>
    </>
  )
}

function CarouselBlock() {
  const isMobile = useIsMobile()
  const imageName = isMobile ? 'mobile' : 'desktop'
  const images = range(4).map(
    (i) => `/images/subscriptions/${imageName}-${i}.png`,
  )
  return (
    <div
      className={cn(
        isMobile
          ? 'w-full'
          : 'relative z-10 rounded-[60px] bg-purple-light2 px-120 pb-40 pt-48 shadow-[0px_0px_24px_rgba(139,87,232,0.15)]',
      )}
    >
      <div className="mx-auto mb-16 w-300 text-center text-24 font-bold sm:w-auto">
        Общайтесь не только в чате, но и голосом!
      </div>
      <Carousel.Root
        opts={{ loop: false, dragFree: true }}
        className={cn(!isMobile && 'w-1000')}
      >
        <Carousel.Content>
          {images.map((x, index) => (
            <Carousel.Item
              classNameWidth={isMobile ? 'w-300' : undefined}
              key={index}
            >
              <img className="mx-auto" src={x} />
            </Carousel.Item>
          ))}
        </Carousel.Content>
        {!isMobile && (
          <>
            <Carousel.Previous />
            <Carousel.Next />
          </>
        )}
        <Carousel.Dots className="mt-12" />
      </Carousel.Root>
    </div>
  )
}

export const SubscriptionView = () => {
  const isMobile = useIsMobile()
  if (isMobile) {
    return <SubscriptionViewMobile />
  }
  return (
    <div className="h-fit min-h-dvh overflow-hidden p-16 sm:p-80">
      <div className="mx-auto flex flex-col items-center">
        <Header />
        <ChoosePlanTitle />
        <PlansBlock />
        <SecurityBlock />
        <RegistrationForm />
        <hr className="my-48 w-full" />
        <ContactsView compact />
      </div>
    </div>
  )
}
function SecurityBlock() {
  return (
    <>
      <PaymentsImage className="mb-28 max-w-[90%] sm:max-w-full" />
      <div className="mb-16 max-w-600 text-center">
        <FormattedMessage id="subscription.security" />
      </div>
    </>
  )
}

function PlansBlock() {
  const intl = useIntl()
  return (
    <div className="group mb-24 sm:w-max">
      <div className="relative flex flex-col items-center gap-2 sm:flex-row">
        <div className="absolute right-0 top-[-92px]">
          <VideoAvatarBlock
            className="sm:mr-60"
            text={intl.formatMessage({ id: 'subscription.edman' })}
            videoUrl={getVideoUrl(5)}
          />
        </div>
        <Plan level="B1" planType="1M" />
        {/* <Plan level="B1" planType="3M" /> */}
        <Plan level="B1" planType="12M" />
      </div>
    </div>
  )
}

export const planInfos = {
  '1M': {
    month: 1,
    weeks: 4,
    hours: [5, 5, 15],
    words: 150,
    price: { ru: '1990', kz: '9984' },
    discount: '',
    duration: 1,
    lessonCount: '7',
    lessonCountRoleplay: 3,
  },
  '3M': {
    period: 'за 3 месяца',
    discount: '23%',
    month: 3,
    weeks: 12,
    hours: [20, 30, 30],
    words: 350,
    price: { ru: '1390', kz: '4799' },
    duration: 3,
    lessonCount: '21',
    lessonCountRoleplay: 9,
  },
  '12M': {
    period: 'за 1 год',
    month: 12,
    discount: '45%',
    weeks: 52,
    hours: [80, 100, 70],
    words: 2500,
    price: { ru: '5990', kz: '30054' },
    duration: 12,
    lessonCount: '70',
    lessonCountRoleplay: 30,
    plan: '12M',
  },
} satisfies Record<SubscriptionPlan, unknown>

function Header() {
  return (
    <>
      <div className="relative mx-auto mb-16 max-w-fit text-24 font-extrabold text-black sm:text-[48px]">
        <Rive
          className="absolute left-[-100px] top-[-60px] size-[340px]"
          src="/animations/subscriptions_header.riv"
        />
        <div className="absolute left-[-600px] top-0 size-[630px] rounded-full bg-[#F8E8DE]/30 blur-[100px]" />
        <div className="absolute bottom-[-800px] right-[-400px] size-[500px] rounded-full bg-[#F8E8DE]/30 blur-[100px]" />

        <div className="relative max-w-800 text-center leading-[1.2em] ">
          <FormattedMessage id="subscription.title" />
          <div className="text-main-light">
            <FormattedMessage id="subscription.subtitle" />
          </div>
        </div>
      </div>
      <ProgramLink />
    </>
  )
}

function ProgramLink() {
  const authStore = useAppModule().authStore
  const hideProgramButton = authStore.state.isAnonOrLogout
  return (
    <LinkButton
      to={urls.index}
      bg="blue"
      className={cn(
        'sm:mb-40',
        hideProgramButton && 'hidden sm:invisible sm:block',
      )}
      rounded="full"
    >
      Программа обучения
    </LinkButton>
  )
}

export function SubscriptionContent(props: {
  planType: SubscriptionPlan
  level: string
  className?: string
}) {
  const planInfo = planInfos[props.planType]
  const levelA = props.level == 'A2'
  const intl = useIntl()
  function line(value: string, text: string) {
    return (
      <div className="text-16 font-semibold text-light sm:whitespace-nowrap">
        <span className="text-20 font-bold">{value}</span> {text}
      </div>
    )
  }
  const content = [
    line(
      intl.formatMessage({ id: 'weeks' }, { count: planInfo.weeks }),
      intl.formatMessage({ id: 'Personalized Program' }),
    ),
    line(
      planInfo.lessonCount,
      intl.formatMessage({ id: 'General Conversational lessons' }),
    ),
    line(planInfo.lessonCount, intl.formatMessage({ id: 'Grammar Lessons' })),
    line(
      planInfo.lessonCount,
      intl.formatMessage({ id: 'Vocabulary Lessons' }),
    ),
    levelA
      ? null
      : line(
          planInfo.lessonCountRoleplay.toString(),
          intl.formatMessage(
            { id: 'Role Play' },
            { count: planInfo.lessonCountRoleplay },
          ),
        ),
  ]

  return (
    <div className={props.className}>
      <div className="flex flex-col gap-8 text-16 font-semibold">
        {content.filter(Boolean).map((x, i) => (
          <div className="flex items-center gap-8" key={i}>
            <Icon
              className="shrink-0 text-green"
              iconName="checkGreen"
              size="sm"
            />{' '}
            {x}
          </div>
        ))}
      </div>

      <div className="mt-8 flex flex-row justify-between text-18 font-bold sm:flex-col sm:text-20">
        <div className="flex items-center gap-4 whitespace-nowrap">
          <span className="align-middle text-20 sm:ml-[-10px] sm:text-32">
            📖{' '}
          </span>
          +{planInfo.words}{' '}
          {intl.formatMessage({ id: 'words' }, { count: planInfo.words })}
        </div>
        <div className="flex  items-center gap-4 whitespace-nowrap">
          <span className="ml-[-8px] align-middle text-20 sm:text-32">⏱️ </span>{' '}
          {intl.formatMessage(
            { id: 'subscription.hours' },
            { count: planInfo.hours[2] },
          )}
        </div>
      </div>
    </div>
  )
}

function RegistrationForm() {
  const authStore = useAppModule().authStore
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)
  const divRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (state.showForm) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [state.showForm, state.plan])

  const planInfo = planInfos[state.plan]
  return (
    <div ref={divRef} className={cn('px-16', !state.showForm && 'hidden')}>
      <div>
        <div className="mb-8 mt-60 max-w-800 text-36 font-extrabold leading-none">
          <FormattedMessage id="Please enter your email" />
        </div>
        <div className="text-18 font-bold">
          <FormattedMessage id="sendPassword" />
        </div>
      </div>
      <div className="mx-auto max-w-400">
        <RegLoginForm
          registrationWithoutPassword={true}
          disableButton={!state.recurrent}
          btnText="Продолжить"
          login={false}
          recurrentText={
            <>
              <FormattedMessage
                id="recurrent"
                values={{ count: planInfo.month }}
              />
            </>
          }
          onSubmit={(data) => {
            void buyStore.buyWithEmail(data.login)
            authStore.trackEvent('click_buy')
          }}
        />
      </div>
    </div>
  )
}

function Plan(props: { planType: SubscriptionPlan; level: string }) {
  const planType = props.planType
  const planInfo = planInfos[planType]
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)

  return (
    <div
    // className={cn('shrink-0', props.planType == '3M' ? 'w-[420px]' : 'w-400')}
    >
      <div
        className={cn(
          'relative flex h-full flex-col justify-between rounded-32 border-12 transition-all duration-300',
          state.plan == planType
            ? 'relative z-10 px-16 pb-20 pt-40 shadow-plan'
            : 'px-12 pb-12 pt-28',
          props.planType == '1M' && 'hover:-translate-x-12',
          props.planType == '12M' && 'hover:translate-x-12',
          state.plan == planType
            ? 'border-blue4 bg-white hover:!bg-white group-hover:bg-purple-light2'
            : 'border-purple-light2 bg-purple-light2 hover:border-purple-light hover:bg-purple-light',
        )}
      >
        {/* {props.planType == '3M' && (
          <DiscountMonthsImage className="absolute -right-44  -top-24 z-10" />
        )} */}
        {props.planType == '12M' && (
          <DiscountYearImage className="absolute -right-44 -top-24 z-20" />
        )}
        <div className="flex flex-col items-center">
          <div className="text-center text-32 font-bold">
            <div className="mb-4">
              <FormattedMessage
                id="months"
                values={{ count: planInfo.duration }}
              />
            </div>
            <div className="text-gray-dark">{planInfo.price.ru}₽</div>
          </div>
        </div>
        <PlanButton planType={props.planType} />
        <SubscriptionContent
          className="ml-20"
          planType={planType}
          level={props.level}
        />
      </div>
    </div>
  )
}

function PlanButton(props: { planType: SubscriptionPlan }) {
  const authStore = useAppModule().authStore
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)
  return (
    <>
      <div className="my-12 sm:my-12">
        <Button
          size="custom"
          rounded="full"
          disabled={state.loading || !state.recurrent}
          className={'mx-auto mb-8 h-52 w-full px-16 sm:h-72 sm:w-[340px]'}
          id={'subscription_trial_' + props.planType}
          bg="blue"
          onClick={() => {
            void buyStore.buy(props.planType, true)
            authStore.trackEvent('subscribtion_trial_click')
          }}
        >
          <span className="flex flex-col gap-2">
            <span className="text-24 font-extrabold leading-none">
              Попробовать за 1 ₽
            </span>
          </span>
        </Button>
      </div>

      <div
        className={cn('mb-12 text-center text-14 font-extrabold text-light')}
      >
        <div>Полный доступ на 7 дней за 1 ₽</div>
        <div>Отмена в любое время</div>
      </div>
    </>
  )
}

function PlanMobile(props: { planType: SubscriptionPlan; level: string }) {
  const planType = props.planType
  const planInfo = planInfos[planType]
  const buyStore = useBuyStore()
  const state = useSnapshot(buyStore.state)
  const currentPlan = planType == state.plan

  return (
    <div className="overflow-hidden rounded">
      {planInfo.discount && (
        <div className="flex h-20 items-center justify-center bg-blue4 text-10 font-extrabold uppercase text-white">
          экономия {planInfo.discount}
        </div>
      )}
      <div
        className={cn(
          'cursor-pointer overflow-hidden bg-white p-24 transition-colors',
          currentPlan ? 'border-blue4' : 'border-transparent',
          planInfo.discount
            ? 'rounded-b border-x-4 border-b-4'
            : 'rounded border-4',
        )}
        onClick={() => {
          buyStore.setPlan(props.planType)
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-1 items-center gap-24">
            <div
              className={cn(
                'size-24 shrink-0 rounded-full transition-all',
                currentPlan ? 'border-5 border-blue4' : 'border-1 border-gray',
              )}
            ></div>
            <div className="flex-1">
              <div className="text-20 font-extrabold leading-[32px]">
                <FormattedMessage
                  id="Months"
                  values={{ count: planInfo.duration }}
                />
              </div>
            </div>
          </div>
          <div className="text-24 font-extrabold text-blue4">
            {planInfo.price.ru}₽
          </div>
        </div>

        <AnimatePresence>
          {currentPlan && (
            <motion.div
              className="overflow-hidden"
              initial={{ opacity: 0, height: '0' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0' }}
            >
              <PlanButton planType={props.planType} />
              <SubscriptionContent planType={planType} level={props.level} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export const AnimateChangeInHeight = (props: {
  className?: string
  state: unknown
  children: React.ReactNode
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | 'auto'>('auto')

  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.offsetHeight)
    }
  }, [props.state])

  return (
    <motion.div
      className={cn('overflow-hidden', props.className)}
      transition={{ duration: 0.3 }}
      animate={{ height }}
    >
      <motion.div
        key={String(props.state)}
        transition={{ delay: 0.2 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        ref={containerRef}
      >
        {props.children}
      </motion.div>
    </motion.div>
  )
}

function SubscriptionViewMobile() {
  return (
    <div className="flex flex-col items-center bg-light pt-16">
      <div className="mb-12 text-center text-[40px] font-extrabold leading-none">
        <FormattedMessage id="subscription.title" />
        <div className="text-blue4">
          <FormattedMessage id="subscription.subtitle" />
        </div>
      </div>

      <div className="mb-8 text-center text-16 font-semibold">
        <FormattedMessage id="subscription.description" />
      </div>
      <ProgramLink />

      <Rive
        className="relative h-[80px] w-full"
        src="/animations/soundwave_background_mobile.riv"
      />

      <CarouselBlock />

      <div className="my-40 flex flex-col gap-16 px-16">
        <div className="text-center text-36 font-extrabold leading-none">
          <FormattedMessage id="subscription.choose" />
        </div>
        <PlanMobile level="B1" planType="1M" />
        {/* <PlanMobile level="B1" planType="3M" /> */}
        <PlanMobile level="B1" planType="12M" />
      </div>
      <SecurityBlock />
      <div className="w-full rounded-t bg-white px-16 py-24 shadow-edge">
        <RegistrationForm />
        <ContactsView compact />
      </div>
    </div>
  )
}
